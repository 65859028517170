import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  
    <Layout pageClass='error-page'>
    <SEO title="404: Not found" />
        <main className='main'>
            <div className='typography inner'>
                <h1>PAGE NOT FOUND</h1>
                <p>The page you are looking for does not exist.</p>
            </div>
        </main> 
    </Layout>
)

export default NotFoundPage
